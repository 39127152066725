<template>
  <b-container fluid class="mb-2">
    <b-row>
      <b-button @click="onShowAddSnItemModalClick">{{ $t("inventory.add-sn-item-btn-caption") }}</b-button>
      <b-modal
        id="add-sn-item-modal"
        @ok="addSnItem"
        size="lg"
        :title="$t('inventory.add-modal.title')"
        :ok-title="$t('inventory.add-modal.ok-btn-caption')"
        :cancel-title="$t('inventory.add-modal.cancel-btn-caption')"
        :ok-disabled="!isValid"
      >
        <template>
          <b-row>
            <b-col class="my-2">
              <v-select
                v-model="selectedGoodsInfo"
                :options="goodsInfos"
                :getOptionLabel="x => `${x.title[$i18n.locale]} - ${x.code}`"
                :placeholder="$t('inventory.add-modal.select-goods-placeholder')"
              >
                <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
              </v-select>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col sm="3">
              <label for="add-sn-item-serial-number" class="mt-1 ml-1">
                {{ $t("inventory.add-modal.serial-number") }}:
              </label>
            </b-col>
            <b-col sm="9">
              <b-form-input id="add-sn-item-serial-number" v-model="serialNumber" :state="isSnValid"></b-form-input>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api.js";
import eventBus from "@/services/event-bus.js";
import { SN_ITEM_ADDED } from "@/const/events.js";

export default {
  data() {
    return this.getDefaultData();
  },
  computed: {
    isSnValid() {
      return this.serialNumber !== "";
    },
    isGoodsInfoSelected() {
      return this.selectedGoodsInfo != null;
    },
    isValid() {
      return this.isSnValid && this.isGoodsInfoSelected;
    }
  },
  methods: {
    getDefaultData() {
      return {
        goodsInfos: [],
        selectedGoodsInfo: null,
        serialNumber: ""
      };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },
    onShowAddSnItemModalClick() {
      this.reset();
      api
        .getGoodsWithSn()
        .then(resp => {
          this.goodsInfos = resp.data;
          this.$bvModal.show("add-sn-item-modal");
        })
        .catch(() => alert(this.$t("inventory.error.while-retrieving-goods-list")));
    },
    addSnItem(evt) {
      evt.preventDefault();
      let vm = {
        goodsInfoId: this.selectedGoodsInfo.id,
        serialNumber: this.serialNumber
      };
      api
        .addSnItem(vm)
        .then(resp => {
          let snItemLink = this.$createElement(
            "b-link",
            {
              class: "toast-body router-link-active",
              props: { to: `/serial-number-item/${resp.data.id}`, target: "_blank" }
            },
            this.$t("inventory.sn-item-added-toast-link")
          );

          this.$root.$bvToast.toast(snItemLink, {
            title: this.$t("inventory.sn-item-added-toast-title"),
            autoHideDelay: 5000,
            appendToast: true
          });
          eventBus.$emit(SN_ITEM_ADDED);
          this.$bvModal.hide("add-sn-item-modal");
        })
        .catch(err => {
          if (err.data.errorCode) {
            alert(this.$t(`inventory.error.${err.data.errorCode}`));
          } else {
            alert(this.$t("inventory.error.while-adding"));
          }
        });
    }
  }
};
</script>
