<style scoped>
.gray-container {
  background-color: #eceeef;
  border-radius: 5px;
}
</style>

<template>
  <div>
    <b-container>
      <b-row class="mb-2">
        <b-col class="p-0">
          <b-overlay :show="areGoodsInfosLoading">
            <v-select
              v-model="selectedGoodsInfo"
              :options="goodsInfos"
              :getOptionLabel="x => `${x.title[$i18n.locale]} - ${x.code}`"
              :placeholder="$t('inventory.sn-items.select-goods-placeholder')"
              @input="onSelectedGoodsInfoIdChanged"
            >
              <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
            </v-select>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row v-if="selectedGoodsInfo" class="gray-container mb-2">
        <b-col cols="2">
          <b-col>{{ $t("inventory.sn-items.general-info.total", { quantity: shortInfo.total }) }}</b-col>
          <b-col>{{ $t("inventory.sn-items.general-info.at-warehouse", { quantity: shortInfo.atWarehouse }) }}</b-col>
          <b-col>{{ $t("inventory.sn-items.general-info.loaded", { quantity: shortInfo.loaded }) }}</b-col>
        </b-col>
        <b-col>
          <b-col>{{ $t("inventory.sn-items.general-info.missing", { quantity: shortInfo.missing }) }}</b-col>
          <b-col>{{ $t("inventory.sn-items.general-info.broken", { quantity: shortInfo.broken }) }}</b-col>
        </b-col>
      </b-row>
      <b-row class="gray-container mb-2 p-3">
        <b-col>
          <b-input-group>
            <b-form-checkbox v-model="filter.showWarehouseItems" switch>
              {{ $t("inventory.sn-items.warehouse-items") }}
            </b-form-checkbox>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox v-model="filter.showLoadedItems" switch>
              {{ $t("inventory.sn-items.loaded-items") }}
            </b-form-checkbox>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox v-model="filter.showMissingItems" switch>
              {{ $t("inventory.sn-items.missing-items") }}
            </b-form-checkbox>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-checkbox v-model="filter.showBrokenItems" switch>
              {{ $t("inventory.sn-items.broken-items") }}
            </b-form-checkbox>
          </b-input-group>
        </b-col>
      </b-row>
    </b-container>
    <b-table
      :fields="fields"
      :items="filteredItems"
      show-empty
      :busy="isLoading"
      :empty-text="$t('inventory.sn-items.table.empty-text')"
      responsive
    >
      <template v-slot:top-row>
        <td></td>
        <td>
          <b-input v-model="filter.serialNumber"></b-input>
        </td>
        <td>
          <b-input v-model="filter.location"></b-input>
        </td>
        <td></td>
        <td>
          <b-form-datepicker
            v-model="filter.lastUpdatedAt"
            :locale="$i18n.locale"
            reset-button
            :label-reset-button="$t('inventory.sn-items.table.datepicker-reset-btn-caption')"
            placeholder=""
            boundary="window"
          ></b-form-datepicker>
        </td>
        <td>
          <b-input v-model="filter.lastUpdatedBy"></b-input>
        </td>
      </template>
      <template v-slot:cell(lastUpdatedAt)="row">
        <span v-if="row.value">
          {{ $d(Date.parse(row.value), "log") }}
        </span>
      </template>
      <template v-slot:cell(title)="row">
        {{ row.value[$i18n.locale] }}
      </template>
      <template v-slot:cell(serialNumber)="row">
        <b-link :to="{ name: 'serial-number-item', params: { serialNumberItemId: row.item.id } }">
          {{ row.value }}
        </b-link>
      </template>
      <template v-slot:cell(status)="row">
        <b-badge v-if="row.item.isLost" variant="warning">{{ $t("inventory.sn-items.status.lost") }}</b-badge>
        <b-badge v-if="row.item.isBroken" variant="danger">{{ $t("inventory.sn-items.status.broken") }}</b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import api from "@/services/api.js";
import comparator from "@/services/string-comparator.js";
import { WAREHOUSE, DECOMMISSIONED } from "@/const/load-item-locations.js";
import eventBus from "@/services/event-bus.js";
import { SN_ITEM_ADDED } from "@/const/events.js";

export default {
  data() {
    return {
      items: [],
      isLoading: false,
      areGoodsInfosLoading: false,
      goodsInfos: [],
      selectedGoodsInfo: null,
      filter: {
        showWarehouseItems: false,
        showLoadedItems: false,
        showMissingItems: false,
        showBrokenItems: false,
        serialNumber: "",
        location: "",
        status: "",
        lastUpdatedAt: "",
        lastUpdatedBy: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("inventory.sn-items.table.title") },
        { key: "serialNumber", label: this.$t("inventory.sn-items.table.serial-number"), sortable: true },
        { key: "location", label: this.$t("inventory.sn-items.table.location"), sortable: true },
        { key: "status", label: this.$t("inventory.sn-items.table.status"), sortable: true },
        { key: "lastUpdatedAt", label: this.$t("inventory.sn-items.table.last-updated-at"), sortable: true },
        { key: "lastUpdatedBy", label: this.$t("inventory.sn-items.table.last-updated-by"), sortable: true }
      ];
    },
    filteredItems() {
      // basic filters
      let result = this.items.filter(
        x =>
          comparator.ciIncludes(x.serialNumber, this.filter.serialNumber) &&
          comparator.ciIncludes(x.location, this.filter.location) &&
          comparator.ciIncludes(x.lastUpdatedBy, this.filter.lastUpdatedBy) &&
          comparator.ciIncludes(x.lastUpdatedAt, this.filter.lastUpdatedAt)
      );

      //switch filters
      if (
        this.filter.showWarehouseItems ||
        this.filter.showLoadedItems ||
        this.filter.showMissingItems ||
        this.filter.showBrokenItems
      ) {
        // if at least one switch is on
        result = result.filter(x => {
          if (this.filter.showWarehouseItems && x.location === WAREHOUSE) return true;
          if (this.filter.showLoadedItems && x.location !== WAREHOUSE && x.location !== DECOMMISSIONED) return true;
          if (this.filter.showMissingItems && x.isLost) return true;
          if (this.filter.showBrokenItems && x.isBroken) return true;
          return false;
        });
      }

      return result;
    },
    shortInfo() {
      let result = {};
      result.total = this.items.length;
      result.atWarehouse = this.items.filter(x => x.location === WAREHOUSE).length;
      result.loaded = this.items.filter(x => x.location !== WAREHOUSE && x.location !== DECOMMISSIONED).length;
      result.missing = this.items.filter(x => x.isLost).length;
      result.broken = this.items.filter(x => x.isBroken).length;

      return result;
    }
  },
  methods: {
    onSelectedGoodsInfoIdChanged(evt) {
      if (evt) {
        this.loadData();
      } else {
        this.items = [];
      }
    },
    loadData() {
      if (this.selectedGoodsInfo) {
        this.isLoading = true;
        api
          .getSnItemsByGoodsInfoId(this.selectedGoodsInfo.id)
          .then(resp => (this.items = resp.data))
          .catch(() => alert(this.$t("inventory.error.while-retrieving-goods-list")))
          .finally(() => (this.isLoading = false));
      }
    }
  },
  mounted() {
    this.areGoodsInfosLoading = true;
    api
      .getGoodsWithSn()
      .then(resp => (this.goodsInfos = resp.data))
      .catch(() => alert(this.$t("inventory.error.while-loading-sn-items")))
      .finally(() => (this.areGoodsInfosLoading = false));
    eventBus.$on(SN_ITEM_ADDED, this.loadData);
  },
  beforeDestroy() {
    eventBus.$off(SN_ITEM_ADDED, this.loadData);
  }
};
</script>
