<style scoped>
.overflowable {
  overflow: auto;
  overflow-x: hidden;
}
</style>
<template>
  <div>
    <div style="display: flex; flex-direction: column">
      <div>
        <add-sn-item></add-sn-item>
      </div>
      <div style="flex: 1">
        <sn-items></sn-items>
      </div>
    </div>
  </div>
</template>
<script>
import AddSnItem from "@/components/inventory/AddSnItem.vue";
import SnItems from "@/components/inventory/SnItems.vue";
export default {
  components: {
    AddSnItem,
    SnItems
  }
};
</script>
